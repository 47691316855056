import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function Sidebar({ isOpen, sidebarRef, onClose }) {
  return (
    <aside
      ref={sidebarRef}
      className={`col-span-1 row-span-2 bg-customBlue text-white p-4 fixed h-full w-[300px] shadow-custom transition-transform duration-300 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0`}
    >
      <div className="flex justify-between items-center">
     
        <button onClick={onClose} className="md:hidden">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <img
          className="w-[126px] h-[115px]"
          src="/assests/hubbleLogo.png"
          alt="logo"
        />
      </div>
      <div className="mt-16 border-b border-gray-700 w-[270px]">
        <nav>
          <ul>
          
            <li className="mb-4">
              <NavLink
                to="/"
                end
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/Home.png"
                            : "/assests/HomeActive.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Dashboard
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            {/* Users link */}
            <li className="mb-4">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/UsersIconActive.svg"
                            : "/assests/Users Icon.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Users
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
           
            <li className="mb-4">
              <NavLink
                to="/bookings"
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/bookingsActive.png"
                            : "/assests/Bookings.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Bookings
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            {/* Artists link */}
            <li className="mb-4">
              <NavLink
                to="/artists"
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/StarActive.png"
                            : "/assests/Star.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Artists
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            {/* Report link */}
            <li className="mb-4">
              <NavLink
                to="/report"
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/integrationActive.png"
                            : "/assests/Integration.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Report
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            {/* Review Feature link */}
            <li className="mb-4">
              <NavLink
                to="/review"
                className={({ isActive }) =>
                  isActive
                    ? "text-customLink"
                    : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/StarActive.png"
                            : "/assests/Star.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Review Feature
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            <li className="mb-4">
              <NavLink
                to="/claim-requests"
                className={({ isActive }) =>
                  isActive ? "text-customLink" : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/UsersIconActive.svg"
                            : "/assests/Users Icon.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Claim Requests
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
            <li className="mb-4">
              <NavLink
                to="/mapped-accounts"
                className={({ isActive }) =>
                  isActive ? "text-customLink" : "text-white hover:text-gray-300"
                }
              >
                {({ isActive }) => (
                  <div className="flex flex-row items-center justify-between w-[250px]">
                    <div className="flex flex-grow gap-2">
                      <img
                        className="w-[14px] h-[14px] mt-1"
                        src={
                          isActive
                            ? "/assests/StarActive.png"
                            : "/assests/Star.png"
                        }
                        alt="icon"
                      />
                      <span className="font-poppins text-16px font-medium leading-14px text-left">
                        Mapped Accounts
                      </span>
                    </div>
                    <img
                      className="w-[7px] h-[7px] fill-current"
                      src="/assests/Chevron.png"
                      alt="icon"
                    />
                  </div>
                )}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
