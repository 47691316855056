import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../../Services/Api";
import { useAuth } from "../../Components/Auth/AuthContext";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
});

const LoginForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ email: "", password: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const payload = {
            email: values.email,
            password: values.password,
            roles: "ADMIN",
          };
          await login(payload);
          navigate("/");
        } catch (error) {
          setErrorMessage("Wrong Password");
          console.error("Login failed:", error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className="login flex justify-center items-center h-screen">
          <div className="form bg-white rounded-lg max-w-sm w-full p-8">
            <span className="text-4xl text-blue-500 mb-6 block">Hubble</span>

            <form noValidate onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Enter email id / username"
                className="form-control mb-4 p-3 w-full rounded-md"
                id="email"
              />

              <p className="error text-left mb-4">
                {errors.email && touched.email && errors.email}
              </p>

              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Enter password"
                className="form-control mb-4 p-3 w-full rounded-md"
              />

              <p className="error text-left mb-4">
                {errors.password && touched.password && errors.password}
              </p>

              <button
                type="submit"
                className="bg-blue-500 text-white rounded-md py-3 px-4 w-full"
              >
                Login
              </button>

              {errorMessage && (
                <p className="error text-left mt-4">{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default LoginForm;
