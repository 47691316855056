import { useEffect, useState } from "react";
import React from "react";
import Layout from "../../Components/Layout";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getArtistReview, deleteArtistReview } from "../../Services/Api";

function Review() {
  const { artistId } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await getArtistReview(artistId);
        const reviewData = response;
        setReviewData(reviewData.reviews);
        setAverageRating(reviewData.averageRating);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchReviews();
  }, [artistId]);

  const handleDelete = async (reviewId, index) => {
    try {
      await deleteArtistReview(reviewId);
      const updatedReviews = reviewData.filter((_, i) => i !== index);
      setReviewData(updatedReviews);

      const totalRating = updatedReviews.reduce(
        (sum, review) => sum + parseFloat(review.rating),
        0
      );
      setAverageRating(
        updatedReviews.length > 0
          ? (totalRating / updatedReviews.length).toFixed(1)
          : 0
      );
    } catch (err) {
      setError(err.message);
    }
  };

  if (error) {
    return (
      <Layout>
        <div className="container bg-customBlue min-h-screen p-8">
          <p className="text-white font-poppins">
            Fetching reviews failed: {error}
          </p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container bg-customBlue min-h-screen p-8">
        <div className="flex flex-col flex-start mt-4">
          <h1 className="text-4xl text-white font-poppins font-semibold mb-2 ml-5">
            Reviews
          </h1>
          <div className="flex items-center text-white mb-4 ml-5">
            <span className="text-2xl font-bold">{averageRating}</span>
            <div className="ml-2 flex">
              {Array.from({ length: 5 }, (_, i) => (
                <svg
                  key={i}
                  className={`h-6 w-6 ${
                    i < averageRating ? "text-yellow-500" : "text-gray-400"
                  }`}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.431L24 9.78l-6 5.851 1.418 8.269L12 18.896l-7.418 4.004L6 15.631 0 9.78l8.332-1.762z" />
                </svg>
              ))}
            </div>
            <span className="ml-2">({reviewData.length} reviews)</span>
          </div>
          <div className="w-full max-w-3xl p-6 rounded-lg shadow-lg">
            {reviewData.length > 0 ? (
              reviewData.map((review, index) => (
                <div
                  key={index}
                  className="mb-6 p-4 bg-customColor rounded-lg shadow-lg"
                >
                  <div className="flex items-center mb-4 font-poppins">
                    <img
                      src={review.userImage || "https://via.placeholder.com/50"}
                      alt={`${review.userName} profile`}
                      className="w-12 h-12 rounded-full bg-gray-300"
                    />
                    <div className="ml-4 flex-1">
                      <p className="text-white text-lg font-semibold">
                        {review.userName}
                      </p>
                      <p className="text-gray-400 text-sm">
                        {new Date(review.reviewDate).toLocaleDateString()}
                      </p>
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-gray-500 cursor-pointer"
                      onClick={() => handleDelete(review.reviewId, index)}
                    />
                  </div>
                  <div className="flex items-center text-white ml-12 mb-4">
                    {Array.from({ length: 5 }, (_, i) => (
                      <svg
                        key={i}
                        className={`h-6 w-6 ${
                          i < parseFloat(review.rating)
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.431L24 9.78l-6 5.851 1.418 8.269L12 18.896l-7.418 4.004L6 15.631 0 9.78l8.332-1.762z" />
                      </svg>
                    ))}
                    <span className="ml-2">{review.rating}</span>
                  </div>
                  <p className="text-white mb-4 ml-12 font-poppins">
                    {review.review}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-white font-poppins">No reviews available.</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Review;
