import React from "react";
import { useTable } from "react-table";

const RecentBookingsTable = ({ columns, data }) => {
  const emptyRowsCount = Math.max(0, 6 - data.length);



  const tableData = [...data];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

  return (
    <table
      {...getTableProps()}
      className="border-collapse overflow-hidden border-customBlue w-[1100px]  "
      style={{
        borderRadius: "15px",
        boxShadow: "1px 1px 1px 0px #10193466",
      }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="bg-customColor">
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className="p-2 text-white font-poppins h-[60px]"
                style={{
                  fontSize: "16px",
                  lineHeight: "10px",
                  fontWeight: "500",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={index % 2 === 0 ? "bg-customBlue" : "bg-customColor"}
              style={{ height: "60px" }}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className="text-white text-center font-poppins p-2"
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RecentBookingsTable;
