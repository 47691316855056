import React, { createContext, useContext, useState, useEffect } from "react";
import { LoginUser } from "../../Services/Api";
import {Api} from "../../Services/Api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("accessToken")
  );

  const login = async (credentials) => {
    try {
      const response = await LoginUser(credentials);
      const accessToken = response.accessToken;
      localStorage.setItem("accessToken", accessToken);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Login failed:", error);
      throw new Error("Login failed");
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
  };

 useEffect(() => {
   const requestInterceptor = Api.interceptors.request.use((config) => {
     const token = localStorage.getItem("accessToken");
     if (token) {
       config.headers.Authorization = `Bearer ${token}`;
     }
     return config;
   });

   const responseInterceptor = Api.interceptors.response.use(
     (response) => response,
     (error) => {
       if (error.customError === "403") {
         logout();
       }
       return Promise.reject(error);
     }
   );

   return () => {
     Api.interceptors.request.eject(requestInterceptor);
     Api.interceptors.response.eject(responseInterceptor);
   };
 }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
