import React, { useEffect, useState } from "react";
import { useAuth } from "./Auth/AuthContext";
import { useNavigate } from "react-router-dom";

function Navbar({ onToggleSidebar }) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email") || "Admin@example.com";
    const userRole = localStorage.getItem("role") || "Admin";

    const extractedUsername = email.split("@")[0];
    setUsername(extractedUsername);
    setRole(userRole);
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <header className="bg-customBlue shadow p-4 md:p-6 h-[100px]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <button
            className="block md:hidden p-2 focus:outline-none"
            onClick={onToggleSidebar}
          >
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
          <div className="ml-4 md:ml-0">
            <h1 className="font-poppins text-[16px] md:text-3xl font-semibold text-white">
              Welcome back, {username}
            </h1>
            <p className="font-poppins text-xs md:text-sm font-normal text-customBorder">
              You are logged in as an {role}. We hope you have a productive day.
            </p>
          </div>
        </div>
        <div className="flex items-center mt-4 md:mt-0">
          <img
            src="/01.png"
            alt="User Avatar"
            className="rounded-full h-10 w-10 md:h-14 md:w-14"
          />
          <div className="flex flex-col ml-2">
            <span className="text-white font-poppins text-sm md:text-lg font-medium">
              {username}
            </span>
            <p className="text-customBorder font-poppins text-xs md:text-sm font-medium">
              {role}
            </p>
          </div>
          <button
            className="w-[100px] md:w-[125px] h-10 rounded-md hover:transform transition-transform duration-200 hover:scale-105 ml-4"
            onClick={handleLogout}
            style={{
              background:
                "linear-gradient(128.49deg, #CB3CFF 19.86%, #7F25FB 68.34%)",
            }}
          >
            <div className="flex items-center justify-center">
              <span className="text-white font-poppins text-[11.36px] md:text-sm font-medium text-center">
                Logout
              </span>
              <img
                className="w-[11.34px] md:w-[14px] h-[11.34px] md:h-[14px] ml-1"
                src="/assests/Arrow Right.png"
                alt="arrow"
              />
            </div>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
