import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="min-h-screen grid grid-rows-[auto_1fr] md:grid-cols-[300px_1fr]">
      <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
      <div className="col-start-1 md:col-start-2 row-start-2 md:row-start-1">
        <Navbar onToggleSidebar={handleToggleSidebar} />
        <main>{children}</main>
      </div>
    </div>
  );
}

export default Layout;
