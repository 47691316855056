import React from "react";
const Dropdown = ({ items, onSelect }) => {
  return (
    <div className="absolute bg-customColor text-white w-[600px] rounded shadow-lg z-10 border border-gray-700"
    style={{marginTop:"75px",marginLeft:"40%"
    }}>
      {items.length > 0 ? (
        <>
          <div className="grid grid-cols-4 gap-3 p-2 bg-gray-800 text-gray-400 border-b border-gray-700">
            <span
              className="text-center mr-8"
              style={{ position: "relative", marginRight:"15px" }}
            >
              Photo
            </span>
            <span
              className="text-center mr-4"
              style={{ position: "relative", left: "10px" }}
            >
              Artist Name
            </span>
            <span
              className="text-center mr-4"
              style={{ position: "relative", left: "15px" }}
            >
              Popularity
            </span>
            <span
              className="text-center mr-4"
              style={{ position: "relative", left: "20px" }}
            >
              Followers
            </span>
          </div>
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => onSelect(item)}
              className="grid grid-cols-4 gap-2 p-2 hover:bg-gray-600 cursor-pointer items-center"
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-8 h-8 items-center rounded-full ml-6"
              />
              <span className="text-left">{item.name}</span>
              <span className="text-center">{item.popularity}</span>
              <span className="text-center">{item.followers}</span>
            </div>
          ))}
        </>
      ) : (
        <div className="p-2 text-gray-400">No results found</div>
      )}
    </div>
  );
};
export default Dropdown;