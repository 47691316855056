import React, { useEffect, useState } from "react";
import { fetchDashboardData, fetchRecentBookings } from "../Services/Api";
import RecentBookingsTable from "../Components/Tables/RecentBookingsTable";
import useFetch from "../hooks/useFetch";

function MainContent() {
  const [dashboardCardData, setDashboardCardData] = useState({
    userCount: 0,
    artistCount: 0,
    bookingCount: 0,
  });

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const data = await fetchDashboardData();
        setDashboardCardData(data);
        console.log("data card", data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };
    fetchDashboard();
  }, []);

  const columns = [
    {
      Header: "Booking Number",
      accessor: "bookingNumber",
    },
    {
      Header: "Amount",
      accessor: "totalAmount",
    },
    {
      Header: "Date",
      accessor: "bookingStartTime",
    },
  ];

  const { data, loading, error } = useFetch(fetchRecentBookings);

  return (
    <main className="col-start-2 row-start-2 p-4 space-y-4 scroll-smooth bg-customBlue">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 mb-4 sm:grid-cols-2 lg:w-[1140px] md:w-[1000px] ">
        <div className="flex justify-center md:justify-start ">
          <div className="w-[304px] h-[130px] border border-[#343B4F] bg-[#0B1739] rounded-lg">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-poppins text-left text-white font-semibold text-[29px] leading-[39px]">
                {dashboardCardData?.userCount || 0}
              </p>
              <div className="flex flex-row mt-4">
                <img
                  className="w-[14px] h-[14px]"
                  src="./assests/Users Icon.png"
                  alt="logo"
                />
                <p className="whitespace-nowrap font-poppins text-customBorder ml-1 font-medium text-[18px] leading-[17px]">
                  Total Users
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:justify-start">
          <div className="w-[304px] h-[130px] border border-[#343B4F] bg-[#0B1739] rounded-lg">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-poppins text-left text-white font-semibold text-[29px] leading-[39px]">
                {dashboardCardData?.bookingCount || 0}
              </p>
              <div className="flex flex-row mt-4">
                <img
                  className="w-[14px] h-[14px]"
                  src="./assests/Bookings.png"
                  alt="logo"
                />
                <p className="whitespace-nowrap font-poppins text-customBorder ml-1 font-medium text-[18px] leading-[17px]">
                  Total Bookings
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:justify-start ">
          <div className="w-[304px] h-[130px] border border-[#343B4F] bg-[#0B1739] rounded-lg">
            <div className="flex flex-col items-center justify-center mt-6">
              <p className="font-poppins text-left text-white font-semibold text-[29px] leading-[39px]">
                {dashboardCardData?.artistCount || 0}
              </p>
              <div className="flex flex-row mt-4">
                <img
                  className="w-[14px] h-[14px]"
                  src="./assests/Star.png"
                  alt="logo"
                />
                <p className="whitespace-nowrap font-poppins text-customBorder ml-1 font-medium text-[18px] leading-[17px]">
                  Total Artist
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow p-4 rounded-lg overflow-auto mb-20 w-full bg-customBlue border-pink-50">
        <h2 className="mb-4 font-poppins text-left text-white text-[24px] leading-[32px] font-semibold">
          Recent Bookings
        </h2>
        <div className="overflow-x-auto">
          <RecentBookingsTable columns={columns} data={data || []} />
        </div>
        {error && (
          <div className="text-red-500">
            Error loading bookings: {error.message}
          </div>
        )}
      </div>
    </main>
  );
}

export default MainContent;
