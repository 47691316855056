import React from "react";
import Modal from "react-modal";

const ConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  artistName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="relative w-11/12 max-w-md p-6 mx-auto bg-white rounded-md shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
    >
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4 font-poppins">Confirm Delete</h2>
        <p className="font-poppins">Are you sure you want to delete {artistName}?</p>
        <div className="flex justify-end mt-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded mr-2 hover:bg-red-700"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
            onClick={onRequestClose}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
