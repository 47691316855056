import React, { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "0.6px solid #343B4F",
    backgroundColor: "#0B1739",
    color: "#AEB9E1",
    borderRadius: "4px",
    height: "48px",
    marginTop: "25px",
    marginLeft: "50px",
    width: "300px",
    display: "flex",
    alignItems: "center",
  }),
  input: (provided) => ({
    ...provided,
    color: "#AEB9E1",
    flex: 1,
    position: "relative",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#AEB9E1",
    cursor: "pointer",
    position: "absolute",
    display:"flex",
    left:"85%",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0B1739",
    border: "0.6px solid #343B4F",
    zIndex: 10,
    width: "305px",
    left: "50px",
    height: "261px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#AEB9E1",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#343B4F" : "#0B1739",
    color: state.isFocused ? "#ffffff" : "#AEB9E1",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "#343B4F",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const LocationDropdown = ({ selectedLocation, onSelect }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <Select
      options={options}
      value={
        options.find((option) => option.label === selectedLocation) || null
      }
      onChange={(country) => {
        onSelect(country ? country.label : "");
      }}
      styles={customStyles}
      placeholder="Add location"
      isClearable
    />
  );
};

const LocationInput = ({ location, handleSelectCountry }) => {
  const [inputValue, setInputValue] = useState(location);

  useEffect(() => {
    setInputValue(location);
  }, [location]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    handleSelectCountry({ label: newValue });
  };

  const handleDropdownChange = (newValue) => {
    setInputValue(newValue);
    handleSelectCountry({ label: newValue });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        borderTop: "0.6px solid #343B4F",
        borderBottom: "0.6px solid #343B4F",
        height: "130px",
        position: "relative",
        alignItems: "center",
      }}
    >
      <span
        style={{
          marginTop: "25px",
          color: "white",
          fontWeight: "400",
          lineHeight: "14px",
          fontFamily: "Poppins",
        }}
      >
        Location
      </span>
      <LocationDropdown
        selectedLocation={inputValue}
        onSelect={handleDropdownChange}
      />
    </div>
  );
};

export default LocationInput;
