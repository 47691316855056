import React from "react";
import Layout from "../Components/Layout";
import MainContent from "../Components/MainContent";


function Dashboard() {
  return (
    <Layout>
      <MainContent />
    </Layout>
  );
}

export default Dashboard;
