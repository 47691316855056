import React, { useState } from "react";
import Layout from "../Components/Layout";
import DynamicTable from "../Components/Tables/DynamicTable";
import { fetchClaimRequests, handleClaimRequest } from "../Services/Api";
import useFetch from "../hooks/useFetch";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ClaimRequests() {
  const { data: claims, loading, error, refetch } = useFetch(fetchClaimRequests);
  const [processingClaims, setProcessingClaims] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");
  const csvData = claims || [];

  // Filter function for search
  const filteredData = React.useMemo(() => {
    if (!searchQuery.trim() || !claims) return claims;

    return claims.filter(claim => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        claim.claimBy.toLowerCase().includes(searchTerm) ||
        claim.claimedAccount.toLowerCase().includes(searchTerm)
      );
    });
  }, [claims, searchQuery]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAccept = async (claimId) => {
    try {
      setProcessingClaims(prev => new Set(prev).add(claimId));
      await handleClaimRequest(claimId, 'APPROVED');
      
      await refetch();
      toast.success('Claim request accepted successfully', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error in handleAccept:', error);
      toast.error(error.message || 'Failed to accept claim request', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setProcessingClaims(prev => {
        const newSet = new Set(prev);
        newSet.delete(claimId);
        return newSet;
      });
    }
  };

  const handleReject = async (claimId) => {
    try {
      setProcessingClaims(prev => new Set(prev).add(claimId));
      await handleClaimRequest(claimId, 'REJECTED');
      
      await refetch();
      toast.success('Claim request rejected successfully', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error in handleReject:', error);
      toast.error(error.message || 'Failed to reject claim request', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setProcessingClaims(prev => {
        const newSet = new Set(prev);
        newSet.delete(claimId);
        return newSet;
      });
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="flex items-center gap-3">
          <img
            src="./assests/Rectangle 5912.png"
            alt="ID Icon"
            className="w-4 h-4 mr-2 ml-2"
          />
          <span className="ml-10">{value}</span>
        </div>
      ),
    },
    {
      Header: "Claim By",
      accessor: "claimBy",
    },
    {
      Header: "Claimed Account",
      accessor: "claimedAccount",
    },
    {
      Header: "Claim Date",
      accessor: "claimDate",
    },
    {
      Header: "Claim Status",
      accessor: "claimStatus",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        const isProcessing = processingClaims.has(row.original.claimId);
        
        return (
          <div className="flex gap-4">
            <button 
              className={`px-3 py-1 rounded ${
                isProcessing 
                  ? "bg-gray-400 cursor-not-allowed" 
                  : "bg-green-500 hover:bg-green-600"
              } text-white transition-colors`}
              onClick={() => handleAccept(row.original.claimId)}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "APPROVE"}
            </button>
            <button 
              className={`px-3 py-1 rounded ${
                isProcessing 
                  ? "bg-gray-400 cursor-not-allowed" 
                  : "bg-red-500 hover:bg-red-600"
              } text-white transition-colors`}
              onClick={() => handleReject(row.original.claimId)}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "REJECT"}
            </button>
          </div>
        );
      },
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Layout>
      <div className="bg-customBlue overflow-hidden w-full max-w-[1200px] h-[850px] mt-8 p-6 flex flex-col space-y-6 mr-20 ml-14 rounded-lg shadow-lg">
        <div className="flex items-center gap-20">
          <h1
            className="text-2xl font-semibold text-white"
            style={{ fontFamily: "Poppins" }}
          >
            Claim Requests
          </h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by claim by or claimed account..."
                className="p-2 pl-10 rounded-md border bg-customColor w-[260.75px] h-[46px] text-white"
                style={{ borderRadius: "4px", border: "0.6px solid #343B4F" }}
              />
              <img
                src="./assests/Search.png"
                alt="Search Icon"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
            </div>
          </div>
          <div className="flex space-x-1" style={{ position: "relative", left: "440px" }}>
            <CSVLink
              data={csvData}
              headers={columns.map((col) => ({
                label: col.Header,
                key: col.accessor,
              }))}
              filename={"claim-requests.csv"}
              className="text-white p-2 w-[100px] h-[43.88px] rounded-sm hover:transform transition-transform duration-200 hover:scale-105"
              style={{
                border: "1px solid #ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex flex-row gap-3">
                <span
                  className="font-poppins"
                  style={{ fontWeight: "500", lineHeight: "12.78px" }}
                >
                  Export
                </span>
                <img
                  className="transform rotate-90 w-[11.36px] h-[11.36px]"
                  src="./assests/Arrow Right.png"
                  alt="pic"
                />
              </div>
            </CSVLink>
          </div>
        </div>
        <div className="overflow-auto">
          <DynamicTable columns={columns} data={filteredData || []} />
        </div>
      </div>
    </Layout>
  );
}

export default ClaimRequests; 